// src/store/paymentSlice.ts
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';
import {EntityState} from "../../../components/shared/interface/interfaces";
import {defaultValue, IAccount} from "../model/account.model";
import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import axios from "axios";
import {cleanEntity} from "../reducers/entity.utils";
import {ILessonPlan, ILessonPlanResponse} from "../model/lessonplan.model";
import {ICounselingPlan, ICounselingPlanResponse} from "../model/counselingplan.model";
import {IActivityIdeaPlan, IActivityIdeaPlanResponse} from "../model/activityIdeaplan.model";
import {IQuestionGenerator} from "../model/questiongenerator.model";
import {IPaymentMethod} from "../model/paymentMethod.model";


const initialState: EntityState<IPaymentMethod> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
};

const apiUrl = 'api/payment';

export const getAllPaymentMethodAPI = createAsyncThunk(
    'payment/fetch_getAllPaymentMethodAPI',
    async () => {
        const requestUrl = `${apiUrl}/getAllPaymentMethod`;
        return axios.get<IAccount>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)

export const paymentSlice = createEntitySlice({
    name: 'payment',
    initialState,

    extraReducers: (builder) => {
        builder

            .addMatcher(isFulfilled(getAllPaymentMethodAPI), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(getAllPaymentMethodAPI), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = paymentSlice.actions;

export default paymentSlice.reducer;
