import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';
import {EntityState} from "../../../components/shared/interface/interfaces";
import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import axios from "axios";
import {defaultValue, IDiscount} from "../model/discount.model";
import {cleanEntity} from "../reducers/entity.utils";
import {ISubscriptionPlan} from "../model/subscriptionPlan.model";


const initialState: EntityState<IDiscount> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
};

const apiUrl = 'api/subscriptionPlan';
const apiAdminUrl = 'api/admin';

export const getEntities = createAsyncThunk(
    'subscriptionPlan/fetch_all',
    async () => {
        const requestUrl = `${apiUrl}/getAll`;
        return axios.get<ISubscriptionPlan[]>(requestUrl);
    },
    {serializeError: serializeAxiosError}
);
export const getEntitiesAdmin = createAsyncThunk(
    'subscriptionPlan/fetch_all_admin',
    async ({limit = 10, offset = 0}: { limit?: number; offset?: number }) => {
        const requestUrl = `${apiAdminUrl}/getListOfSubscriptionPlans?limit=${limit}&offset=${offset}`;
        return axios.get<ISubscriptionPlan[]>(requestUrl);
    },
    {serializeError: serializeAxiosError}
);

export const createSubscriptionPlanAPI = createAsyncThunk(
    'subscriptionPlan/post_createSubscriptionPlanAPI',
    async (entity: ISubscriptionPlan, thunkAPI) => {
        const requestUrl = `${apiAdminUrl}/createSubscriptionPlans`;
        const result = axios.post<ISubscriptionPlan>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const subscribeUserToPlanAPI = createAsyncThunk(
    'subscriptionPlan/post_subscribeUserToPlanAPI',
    async (entity: { payWithWallet: boolean, subscriptionPlanId: number, paymentMethod: number | null }, thunkAPI) => {
        const requestUrl = `${apiUrl}/subscribeToPlan`;
        const result = axios.post<ISubscriptionPlan>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const subscriptionPlanSlice = createEntitySlice({
    name: 'subscriptionPlan',
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(subscribeUserToPlanAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(subscribeUserToPlanAPI.fulfilled, (state, action) => {
                state.entity = action.payload.data;
                state.loading = false;
            })
            .addCase(subscribeUserToPlanAPI.rejected, (state) => {
                state.loading = false;
            })

            .addMatcher(isFulfilled(getEntities), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = subscriptionPlanSlice.actions;

export default subscriptionPlanSlice.reducer;
