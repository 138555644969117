import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {Button, Card, CircularProgress} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {verifyEmailAPI} from "../../utils/redux/store/authSlice";

const VerifyEmail = () => {
    const dispatch = useAppDispatch()
    const {guid} = useParams<string>()
    const loading: boolean = useAppSelector(state => state.auth.loading);
    const verified: boolean = useAppSelector(state => state.auth.entities);
    const errorMessage: string = useAppSelector(state => state.auth.errorMessage);
    useEffect(() => {
        dispatch(verifyEmailAPI({guid: guid!}))
    }, []);
    if (loading) {
        return (
            <div className="vh-100 align-content-center">
                <Card className="col-12 col-lg-3 mx-auto p-3" id='login-page' variant="elevation" autoFocus={false}>
                    <CircularProgress/>
                </Card>
            </div>
        )
    }
    return (
        <div className="vh-100 align-content-center">
            <Card className="col-12 col-lg-3 mx-auto p-3" id='login-page' variant="elevation" autoFocus={false}>
                {verified ?
                    <div className="col-12 text-center text-success fw-bold mt-3">
                        Your account is verified successfully
                    </div>
                    :
                    <div className="col-12 text-center text-danger fw-bold mt-3">Something went wrong!</div>
                }
                <div className="col-12 text-center mt-4">
                    <Link to="/">
                        <Button variant="contained">Go to Login Page</Button>
                    </Link>
                </div>
            </Card>
        </div>
    );
};

export default VerifyEmail;
