import React, {useState} from 'react';
import {Link, Navigate} from "react-router-dom";
import {
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import {registerUser} from "../../utils/redux/store/authSlice";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {FaRegSquareFull} from "react-icons/fa6";
import {FiCheck} from "react-icons/fi";
import loginslide1 from "../../assets/loginslide1.png";
// @ts-ignore
import MuiImageSlider from "mui-image-slider";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";


export const Register = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation();
    const [reqData, setReqData] = useState({email: '', password: '', isOrganization: false})
    const [orgShow, setOrgShow] = useState(false)
    const registerSuccess: boolean = useAppSelector(state => state.auth.registerSuccess);
    const [showPassword, setShowPassword] = useState(false);
    const [acceptTerm, setAcceptTerm] = useState(false);
    const handleToggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleLoginFacebook = () => {
        toast.warning("under development")
    }
    const handleLoginLinkedin = () => {
        toast.warning("under development")
    }
    const handleLoginGoogle = () => {
        toast.warning("under development")
    }
    const handleRegister = () => {
        dispatch(registerUser(reqData))
    }
    if (registerSuccess) {
        return (
            <Navigate
                to={{
                    pathname: '/login',
                }}
                replace
            />
        )
    }
    return (
        <div className="row mx-0 h-100">
            <div className="col-12 col-lg-6 mt-5 px-0 px-lg-5">
                <div className='row mx-0 justify-content-center mt-5'>
                    <div className='col-12'>
                        <Typography className='text-center' variant='h2' component='h1'>
                            Create an Account
                        </Typography>
                    </div>
                    <div className='col-12 mt-3'>
                        <Typography className='text-center' variant='caption' component='h1'>
                            Create an account to continue
                        </Typography>
                    </div>
                </div>
                <div className='row justify-content-center mt-5'>
                    {orgShow ?
                        <div className='col-10'>
                            <div className="col-12 mt-3">
                                <Typography variant="subtitle1">{t('login.email')}:</Typography>
                                <TextField
                                    className="col-12 mt-2"
                                    variant="filled"
                                    type="email"
                                    placeholder={t('login.emailPlaceholder')}
                                    onChange={(event) => setReqData({
                                        ...reqData,
                                        email: event.target.value
                                    })}
                                />
                            </div>
                            <div className="col-12 mt-4">
                                <div className="row mx-0 col-12 px-0">
                                    <div className="col-6 px-0">
                                        <Typography variant="subtitle1">{t('login.password')}:</Typography>
                                    </div>
                                    <div className="col-6 text-end px-0">
                                        <Typography className='col-12' variant='caption' component='h6'>
                                            <a href='/forget-password' style={{color: '#0D0D10'}}>Forget
                                                Password?</a>
                                        </Typography>
                                    </div>
                                </div>
                                <TextField
                                    className="col-12 mt-2"
                                    variant="filled"
                                    placeholder={t('login.passwordPlaceholder')}
                                    type={showPassword ? "text" : "password"}
                                    onChange={(event) => setReqData({
                                        ...reqData,
                                        password: event.target.value
                                    })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleToggleShowPassword}>
                                                    {showPassword ? <MdVisibilityOff/> : <MdVisibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-12 mt-4">
                                <FormControlLabel onChange={(e, val) => setAcceptTerm(val)}
                                                  control={<Checkbox icon={<FaRegSquareFull size={18}/>}
                                                                     checkedIcon={<FiCheck size={18}/>}/>}
                                                  label="I accept terms and conditions"/>
                            </div>
                            <div className="col-12 mt-4">
                                <div className="col-12 mt-3 px-0 px-lg-4">
                                    <Button className="col-12" variant="contained" color="primary"
                                            disabled={!acceptTerm}
                                            onClick={handleRegister}
                                    >
                                        {t('login.SignUp')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='col-10'>
                            <div className="col-12 mt-3">
                                <Typography className='col-12' variant='caption' component='h6'>
                                    Choose your Register Type
                                </Typography>
                            </div>
                            <div className="col-12 mt-3">
                                <Button className="col-12"
                                        variant="text"
                                        onClick={() => {
                                            setReqData({...reqData, isOrganization: true})
                                            setOrgShow(true)
                                        }}
                                >
                                    Register as an Organization
                                </Button>
                                <Button className="col-12 mt-3"
                                        onClick={() => {
                                            setReqData({...reqData, isOrganization: false})
                                            setOrgShow(true)
                                        }}
                                >
                                    individual Teacher
                                </Button>
                            </div>
                        </div>
                    }
                </div>
                <div className='col-12 text-center mt-3'>
                    <Typography className='col-12 text-center' variant='h5' component='h6'>
                        Already have an account?
                        <a href='/login' style={{color: '#49C12C'}}> Login</a>
                    </Typography>
                </div>
                <div className='row mx-0 col-12 text-center mt-3'>
                    <div className="col-3 align-content-center">Or login with</div>
                    <div className="col-3 text-center"><Button className="bg-white" variant="text"
                                                               style={{color: "#49C12C"}}
                                                               onClick={handleLoginFacebook}>Facebook</Button></div>
                    <div className="col-3 text-center"><Button className="bg-white" variant="text"
                                                               style={{color: "#49C12C"}}
                                                               onClick={handleLoginLinkedin}>Linkedin</Button></div>
                    <div className="col-3 text-center"><Button className="bg-white" variant="text"
                                                               style={{color: "#49C12C"}}
                                                               onClick={handleLoginGoogle}>Google</Button></div>
                </div>
            </div>
            <div className="col-12 col-lg-6 px-0 d-none d-lg-block">
                <MuiImageSlider
                    autoPlay
                    arrowsBgColor="#F4F4F4"
                    alwaysShowArrows
                    images={[
                        loginslide1,
                        loginslide1,
                        loginslide1,
                        loginslide1,
                    ]}
                />
            </div>

        </div>
    )
    return (
        <div className="vh-100 align-content-center">
            <Card className="col-3 mx-auto p-3" id='login-page' variant="elevation" autoFocus={false}>

                <div className='row justify-content-center mt-3'>
                    {orgShow ?
                        <div className='col-10'>
                            <div className="col-12 mt-3">
                                <Typography variant="subtitle1">{t('login.email')}:</Typography>
                                <TextField
                                    className="col-12"
                                    type="email"
                                    placeholder={t('login.emailPlaceholder')}
                                    onChange={(event) => setReqData({
                                        ...reqData,
                                        email: event.target.value
                                    })}
                                />
                            </div>
                            <div className="col-12 mt-4">
                                <Typography variant="subtitle1">{t('login.password')}:</Typography>
                                <TextField
                                    className="col-12"
                                    placeholder={t('login.passwordPlaceholder')}
                                    type="password"
                                    onChange={(event) => setReqData({
                                        ...reqData,
                                        password: event.target.value
                                    })}
                                />
                            </div>
                            <div className="col-12 mt-4">
                                <div className="col-12 mt-3 px-0 px-lg-4">
                                    <Button className="col-12" variant="contained" color="primary"
                                            onClick={handleRegister}
                                    >
                                        {t('login.register')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='col-10'>
                            <div className="col-12 mt-3">
                                <Typography className='col-12' variant='caption' component='h6'>
                                    Choose your Register Type
                                </Typography>
                            </div>
                            <div className="col-12 mt-3">
                                <Button className="col-12"
                                        onClick={() => {
                                            setReqData({...reqData, isOrganization: true})
                                            setOrgShow(true)
                                        }}
                                >
                                    Register as an Organization
                                </Button>
                                <Button className="col-12"
                                        onClick={() => {
                                            setReqData({...reqData, isOrganization: false})
                                            setOrgShow(true)
                                        }}
                                >
                                    individual Teacher
                                </Button>
                            </div>
                        </div>
                    }


                </div>
            </Card>
        </div>

    )
}
