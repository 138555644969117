// SubscriptionPlan.tsx
import React from 'react';
import {Button, Card, CardContent, Typography} from '@mui/material';
import {ISubscriptionPlan} from "../../../utils/redux/model/subscriptionPlan.model";

interface SubscriptionPlanProps {
    plan: ISubscriptionPlan;
    selectedPlan: ISubscriptionPlan;
    onHandleSetSelectedPlan: (plan: ISubscriptionPlan) => void;
}

const SubscriptionPlanContainer: React.FC<SubscriptionPlanProps> = ({plan, onHandleSetSelectedPlan, selectedPlan}) => {
    return (
        <div className="col-3">
            <Card className="col-12">
                <CardContent className="text-center">
                    <Typography className="" variant="h2" component="div">
                        {plan.name}
                    </Typography>
                    <Typography className="mt-2" variant="body2" color="text.secondary">
                        {plan.description || 'No description available'}
                    </Typography>
                    <Typography className="mt-2" variant="h6" component="div" sx={{mt: 1}}>
                        ${plan.price?.toFixed(2) || '0.00'}
                    </Typography>
                    <Typography className="mt-1" variant="body2" color="text.secondary">
                        {plan.duration_days} days
                    </Typography>
                    <Typography className="mt-1" variant="body2">
                        {plan.description}
                    </Typography>
                    <div className="col-12 mt-3 text-center">
                        <Button
                            size="small"
                            variant={selectedPlan.id === plan.id ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => onHandleSetSelectedPlan(plan)}
                        >
                            Subscribe
                        </Button>
                    </div>
                </CardContent>

            </Card>
        </div>
    );
};

export default SubscriptionPlanContainer;
