import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../utils/redux/store";
import {getEntities, subscribeUserToPlanAPI} from "../../../utils/redux/store/subscriptionPlanSlice";
import {ISubscriptionPlan} from "../../../utils/redux/model/subscriptionPlan.model";
import SubscriptionPlanContainer from "./subscriptionPlanContainer";
import {Button, Card, CircularProgress, Typography} from "@mui/material";
import {t} from "i18next";
import {IPaymentMethod} from "../../../utils/redux/model/paymentMethod.model";
import {getAllPaymentMethodAPI} from "../../../utils/redux/store/paymentSlice";
import {IChargeWalletResponse} from "../../../utils/redux/model/chargeWallet.model";
import {checkValidate} from "../../../utils/redux/store/authSlice";

const SubscriptionPlan = () => {
    const dispatch = useAppDispatch()
    const subscriptionPlans: ISubscriptionPlan[] = useAppSelector(state => state.subscriptionPlan.entities);
    const [selectedPlan, setSelectedPlan] = useState({} as ISubscriptionPlan)
    const paymentMethodList: IPaymentMethod[] = useAppSelector(state => state.payment.entities);
    const userBalance: number = useAppSelector(state => state.auth.entity.userBalance);
    const subscription: string = useAppSelector(state => state.auth.entity.subscription);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(1)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        dispatch(getEntities())
        dispatch(getAllPaymentMethodAPI())
    }, []);

    const handleSetSelectedPlan = (plan: ISubscriptionPlan) => {
        // Handle the onHandleSetSelectedPlan logic here
        setSelectedPlan(plan)
    };
    const handlePayment = () => {
        // Handle the subscription logic here
        dispatch(
            subscribeUserToPlanAPI(
                {
                    payWithWallet: false,
                    subscriptionPlanId: selectedPlan.id!,
                    paymentMethod: selectedPaymentMethod
                }
            )
        ).then((res) => {
            const response: IChargeWalletResponse = (res.payload as any).data
            setLoading(false)
            if ((res.payload as any).status === 200) {
                if (typeof response.url === "string") {
                    window.location.href = response.url
                }
            }else{
                window.location.reload()
            }
            dispatch(checkValidate())
        })
    };
    return (
        <Card className="row mx-0 p-4" variant="elevation">
            {subscription ?
                "You already have " + subscription + " plan activated"
                :
                <React.Fragment>
                    {subscriptionPlans.map((subscriptionPlan) => {
                        return <SubscriptionPlanContainer key={subscriptionPlan.id} selectedPlan={selectedPlan} plan={subscriptionPlan}
                                                          onHandleSetSelectedPlan={handleSetSelectedPlan}/>
                    })}
                    <div className="col-12 px-0">
                        <div className="row mx-0 col-12 mt-4">
                            <Typography variant="subtitle1">Payment Method</Typography>
                        </div>
                        <div className="row mx-0 col-12 mt-3">
                            {selectedPlan.id !== undefined && paymentMethodList.map((paymentMethod) => {
                                return (
                                    <div className="col-2">
                                        <Card key={paymentMethod.id}
                                              className={selectedPaymentMethod === paymentMethod.id ? "col-12 border border-2 bg-light" : "col-12 border"}
                                              variant="elevation"
                                              onClick={() => setSelectedPaymentMethod(paymentMethod.id!)}
                                        >
                                            <div className="col-12 text-center">
                                                <img className="col-12" src={paymentMethod.icon!} alt={paymentMethod.name!}/>
                                            </div>
                                            <div className="col-12 px-0 text-center">{paymentMethod.name}</div>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>

                        {/*{userBalance >= selectedPlan.price! ?*/}
                        {/*    <div className="row mx-0 col-12 mt-3">*/}
                        {/*        From User Wallet : ${userBalance}*/}
                        {/*    </div>*/}
                        {/*    :*/}
                        {/*    <div className="row mx-0 col-12 mt-3">*/}
                        {/*        {selectedPlan.id !== undefined && paymentMethodList.map((paymentMethod) => {*/}
                        {/*            return (*/}
                        {/*                <div className="col-2">*/}
                        {/*                    <Card key={paymentMethod.id}*/}
                        {/*                          className={selectedPaymentMethod === paymentMethod.id ? "col-12 border border-2 bg-light" : "col-12 border"}*/}
                        {/*                          variant="elevation"*/}
                        {/*                          onClick={() => setSelectedPaymentMethod(paymentMethod.id!)}*/}
                        {/*                    >*/}
                        {/*                        <div className="col-12 text-center">*/}
                        {/*                            <img className="col-12" src={paymentMethod.icon!} alt={paymentMethod.name!}/>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="col-12 px-0 text-center">{paymentMethod.name}</div>*/}
                        {/*                    </Card>*/}
                        {/*                </div>*/}
                        {/*            )*/}
                        {/*        })}*/}
                        {/*    </div>*/}
                        {/*}*/}

                        <div className="row mx-0 col-12 mt-3 px-0">
                            <Button className="col-12 col-lg-2 gap-2" variant="contained" color="primary"
                                    disabled={loading || selectedPlan.id == undefined}
                                    onClick={!loading ? handlePayment : () => {
                                    }}
                            >
                                {loading ? <CircularProgress size={24}/> : t('pay')
                                }

                                {/*<div className={entityCheckCouponAPI && "text-decoration-line-through"}>*/}
                                {/*    ${walletChargeAmount}*/}
                                {/*</div>*/}
                                {/*{entityCheckCouponAPI &&*/}
                                {/*    <div style={{color: "red"}}>*/}
                                {/*        ${(walletChargeAmount - (entityCheckCouponAPI ? entityCheckCouponAPI.discount_applied : 0))}*/}
                                {/*    </div>*/}
                                {/*}*/}
                            </Button>
                        </div>
                    </div>
                </React.Fragment>

            }

        </Card>
    );
};

export default SubscriptionPlan;
