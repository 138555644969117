import React from 'react'
import {Link} from "react-router-dom";
import {IconType} from "react-icons";

import { Box, Typography, Card, CardContent, Avatar } from '@mui/material';
export const ToolsCard = (props: IToolsCard) => {
    return (
        <div className="row mx-0 col-12 col-md-4 col-lg-3 col-xl-4 col-xxl-2 mt-4 text-center-w">
            <Card className="toolscard" component={Link} to={props.link}
                sx={{
                    width: 250,
                    textAlign: 'center',
                    borderRadius: '16px',
                    border: '1px solid #e0e0e0',
                    boxShadow: 'none'
                }}
            >
                <CardContent>
                    <Avatar
                        sx={{
                            width: 56,
                            height: 56,
                            margin: '0 auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        src={props.icon}
                    >
                    </Avatar>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            marginTop: 2
                        }}
                    >
                        {props.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: 1 }}
                    >
                        {props.desc}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}

export interface IToolsCard {
    link: string,
    title: string,
    desc: string,
    icon: any,
    isComingSoon: boolean
}
