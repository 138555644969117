import React, {useState, useEffect} from 'react';
import {
    Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    CircularProgress, Button, Modal, Box, TextField, MenuItem, TablePagination, FormControlLabel, Checkbox, Tooltip
} from '@mui/material';
import {createSubscriptionPlanAPI, getEntitiesAdmin} from "../../utils/redux/store/subscriptionPlanSlice";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {IoInformationCircle} from "react-icons/io5";
import {ISubscriptionPlan} from "../../utils/redux/model/subscriptionPlan.model";

const SubscriptionPlanManagement = () => {
    const dispatch = useAppDispatch();
    const subscriptionPlans: ISubscriptionPlan[] = useAppSelector(state => state.subscriptionPlan.entities);
    const loading = useAppSelector(state => state.discount.loading);

    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0); // Page number
    const [rowsPerPage, setRowsPerPage] = useState(20); // Rows per page

    const [formData, setFormData] = useState<ISubscriptionPlan>({
        name: "",
        price: 0,
        description: "",
        duration_days: 30
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        // Convert expiration_date to Unix timestamp

        const submissionData = {
            ...formData,
        };

        try {
            dispatch(createSubscriptionPlanAPI(submissionData)).finally(() => {
                fetchSubscriptionPlans();
                handleClose();
            })
            // Refresh the coupon list after successful creation

        } catch (error) {
            console.error("Failed to create coupon", error);
        }
    };

    const fetchSubscriptionPlans = () => {
        dispatch(getEntitiesAdmin({limit: rowsPerPage, offset: page * rowsPerPage}));
    };

    useEffect(() => {
        fetchSubscriptionPlans();
    }, [page, rowsPerPage]);

    const handlePageChange = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when changing rows per page
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Subscription Plan
            </Typography>

            <Button variant="contained" color="primary" onClick={handleOpen}>
                Create New Subscription Plan
            </Button>

            <Modal open={open} onClose={handleClose}>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography className="row col-12 mx-0" variant="h6" component="h2" gutterBottom>
                        <div className="col-6 px-0">Create New Coupon</div>
                        <Tooltip title="Coupon Unique Code"
                                 children={<div className="col-6 text-end px-0 "><IoInformationCircle size={22}/>
                                 </div>}/>
                    </Typography>

                    <TextField
                        fullWidth
                        label="subscription Plan Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        margin="normal"
                        required
                    />
                    <TextField
                        fullWidth
                        label="subscription Plan Price"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        margin="normal"
                        required
                    />
                    <Tooltip
                        title="subscription Plan duration days">
                        <TextField
                            fullWidth
                            select
                            label="Duration days"
                            name="duration_days"
                            value={String(formData.duration_days)}
                            onChange={handleChange}
                            margin="normal"
                            required
                        >
                            <MenuItem value="30">Monthly</MenuItem>
                            <MenuItem value="365">Yearly</MenuItem>
                        </TextField>
                    </Tooltip>
                    <Tooltip title="The description of subscription Plan">
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            label="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />
                    </Tooltip>

                    <Button type="submit" variant="contained" color="primary" sx={{mt: 2}}>
                        Create Subscription
                    </Button>
                </Box>
            </Modal>

            {loading ? (
                <CircularProgress sx={{mt: 4}}/>
            ) : (
                <TableContainer component={Paper} sx={{mt: 4}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>id</TableCell>
                                <TableCell>name</TableCell>
                                <TableCell>price</TableCell>
                                <TableCell>duration days</TableCell>
                                <TableCell>description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {subscriptionPlans.map((subscriptionPlan) => (
                                <TableRow key={subscriptionPlan.id}>
                                    <TableCell>{subscriptionPlan.id}</TableCell>
                                    <TableCell>{subscriptionPlan.name}</TableCell>
                                    <TableCell>{subscriptionPlan.price}</TableCell>
                                    <TableCell>{subscriptionPlan.duration_days}</TableCell>
                                    <TableCell>{subscriptionPlan.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={100} // This should be replaced with the total number of records from your backend
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </TableContainer>
            )}
        </Container>
    );
};

export default SubscriptionPlanManagement;
