import React, {useEffect, useState} from "react";
import {
    Button,
    Card, CircularProgress,
    FormControl, IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent, Skeleton,
    TextField,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {FiRepeat} from "react-icons/fi";
import {FaStar} from "react-icons/fa";
import {MdContentCopy, MdSettingsVoice} from "react-icons/md";
import {IoIosVolumeHigh} from "react-icons/io";
import {toast} from "react-toastify";
import {TfiExport} from "react-icons/tfi";
import {useTranslation} from "react-i18next";
import {IActivityIdeaPlan} from "../../../utils/redux/model/activityIdeaplan.model";
import {ILessonPlanResponse} from "../../../utils/redux/model/lessonplan.model";
import {useAppDispatch, useAppSelector} from "../../../utils/redux/store";
import {activityIdeaPlanAPI, reset} from "../../../utils/redux/store/promptSlice";
import {checkValidate} from "../../../utils/redux/store/authSlice";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import {Document, Packer, Paragraph, TextRun} from "docx";
import {stripHtmlTags} from "../../../utils/stripHtmlTags";
import {saveAs} from "file-saver";
import html2pdf from "html2pdf.js";
import {t} from "i18next";

export const ActivityIdeaGenerator = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [reqData, setReqData] = useState<IActivityIdeaPlan>({gradeLevel: '', topic: '', numberOfParticipant: 0})
    const copyContentRef = React.useRef(null);
    const prompt: ILessonPlanResponse = useAppSelector(state => state.prompt.entities);
    const loading: boolean = useAppSelector(state => state.prompt.loading);

    useEffect(() => {
        return () => {
            dispatch(reset())
        }
    }, []);
    const handleGenerateActivityIdeaPlans = (e: { preventDefault: () => void; }) => {
        if (reqData.topic === '' || reqData.numberOfParticipant === 0 || reqData.gradeLevel === '') {
            toast.warning(t("toast.FillTheFormFirst"))
            return
        }
        dispatch(activityIdeaPlanAPI(reqData)).finally(() => {
            dispatch(checkValidate())
        })

    }
    const handleCopy = () => {
        if (copyContentRef.current) {
            const content = (copyContentRef.current as any).innerHTML;

            // Create a temporary contenteditable div to hold the HTML
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.left = '-9999px';
            tempDiv.style.whiteSpace = 'pre';
            tempDiv.contentEditable = 'true';
            tempDiv.innerHTML = content;
            document.body.appendChild(tempDiv);

            // Select and copy the content
            const range = document.createRange();
            range.selectNodeContents(tempDiv);
            const sel: any = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            // Execute the copy command
            document.execCommand('copy');

            // Clean up
            document.body.removeChild(tempDiv);

            toast.success('Content copied to clipboard.');
        }
    }
    const handleExport = () => {
        if (!copyContentRef.current) {
            toast.error('No content to export');
            return;
        }
        const content = copyContentRef.current;
        const opt = {
            margin: 1,
            filename: 'activityIdea.pdf',
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: 'in', format: 'A4', orientation: 'portrait'}
        };

        html2pdf().set(opt).from(content).save();


        const docs = new Document({
            sections: [{
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: stripHtmlTags((content as any).innerHTML),
                            break: 1
                        })
                    ]
                })]
            }]
        });
        Packer.toBlob(docs).then(blob => {
            saveAs(blob, 'activityIdea.docx');
        });
    }
    const handleReadAloud = () => {
        toast.info("coming soon")
    }
    return (
        <div className="row mx-0">
            {/* @ts-ignore*/}
            <Button
                LinkComponent={Link}
                to="/tools"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                {t('Back')}
            </Button>
            <Card variant="elevation" className="row mx-0 col-11 col-lg-7 mx-auto p-4 mt-5">
                <div className="d-flex gap-2 col-12 px-0">
                    <Button variant="text" color='primary' className="gap-0 px-3"
                            startIcon={<FiRepeat/>}
                            onClick={() => setReqData({gradeLevel: '', topic: '', numberOfParticipant: 0})}
                    >
                        {t('Reset')}
                    </Button>
                    <Button variant="text" color='primary' className="gap-0 px-3"
                            startIcon={<FaStar/>}
                            onClick={() => setReqData({
                                gradeLevel: 'Elementary',
                                topic: 'to teach them about leadership',
                                numberOfParticipant: 5
                            })}
                    >
                        {t('Sample')}
                    </Button>
                </div>
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3">{t('ActivityIdea.title')}</Typography>
                </div>
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3" className="fw-bold text-center poweredbyahura">{t('PoweredByAhuraAI')}</Typography>
                </div>
                <div className="col-12 text-center">
                    <Typography variant="caption">{t('ActivityIdea.Desc')}</Typography>
                </div>
                <div className="col-12 mt-3">
                    <Typography variant="subtitle1">{t('LessonPlan.GradeLevel')}:</Typography>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{t('LessonPlan.Grade')}</InputLabel>
                        <Select
                            variant="outlined"
                            id="demo-simple-select"
                            value={reqData.gradeLevel!}
                            label="Age"
                            onChange={(event: SelectChangeEvent) => setReqData({
                                ...reqData,
                                gradeLevel: event.target.value
                            })}
                        >
                            <MenuItem value="Elementary">{t('LessonPlan.Elementary')}</MenuItem>
                            <MenuItem value="Middle School">{t('LessonPlan.MiddleSchool')}</MenuItem>
                            <MenuItem value="High School">{t('LessonPlan.HighSchool')}</MenuItem>
                            <MenuItem value="College">{t('LessonPlan.College')}</MenuItem>
                            <MenuItem value="Grad School">{t('LessonPlan.GradSchool')}</MenuItem>
                            <MenuItem value="New professional">{t('LessonPlan.Newprofessional')}</MenuItem>
                            <MenuItem value="Expert professional">{t('LessonPlan.Expertprofessional')}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className=" col-12 mt-3">
                    <div className="col-12 position-relative">
                        <Typography variant="subtitle1">{t('LessonPlan.TopicStandardOrObjective')}:</Typography>
                        <TextField
                            className="col-12"
                            placeholder={t('LessonPlan.TopicStandardOrObjectiveDesc')}
                            multiline
                            rows={6}
                            value={reqData.topic}
                            onChange={(event) => setReqData({
                                ...reqData,
                                topic: event.target.value
                            })}
                        />
                        <div className='position-absolute right-4 bottom-2'>
                            {/* for voice and attach */}
                            <IconButton className="col-12">
                                <MdSettingsVoice/>
                            </IconButton>
                            {/*<IconButton className="col-12">*/}
                            {/*    <IoIosAttach/>*/}
                            {/*</IconButton>*/}
                        </div>
                    </div>

                </div>
                <div className="col-12 mt-3">
                    <div className="position-relative">
                        <Typography variant="subtitle1">{t('ActivityIdea.NumberOfParticipant')}:</Typography>
                        <TextField
                            className="col-12"
                            rows={4}
                            type="number"
                            value={reqData.numberOfParticipant}
                            onChange={(event) => setReqData({
                                ...reqData,
                                numberOfParticipant: Number(event.target.value)
                            })}
                        />
                        <div className='position-absolute right-4 bottom-2'>
                            {/* for voice and attach */}
                            {/*<IconButton className="">*/}
                            {/*    <MdSettingsVoice/>*/}
                            {/*</IconButton>*/}
                            {/*<IconButton className="">*/}
                            {/*    <IoIosAttach/>*/}
                            {/*</IconButton>*/}
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3 px-0 px-lg-4">
                    <Button className="col-12" variant="contained" color="primary"
                            onClick={handleGenerateActivityIdeaPlans}
                            disabled={loading}
                    >
                        {loading ? <CircularProgress/> : t('LessonPlan.Generate')}
                    </Button>
                </div>

            </Card>
            {prompt.result && (
                <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5">
                    <div ref={copyContentRef}
                         dangerouslySetInnerHTML={{__html: prompt.result}}
                    />
                    <div className="d-flex gap-3 col-12 text-start">
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<MdContentCopy/>}
                                    onClick={handleCopy}
                            >
                                {t('Copy')}
                            </Button>
                        </div>
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<TfiExport/>}
                                    onClick={handleExport}>
                                {t('Export')}
                            </Button>
                        </div>
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<IoIosVolumeHigh/>}
                                    onClick={handleReadAloud}>
                                {t('ReadAloud')}
                            </Button>
                        </div>

                    </div>
                </Card>
            )}
            {loading &&
                <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5 h-25">
                    <Skeleton className="row mx-0 col-7 mx-auto p-4 mt-5">
                    </Skeleton>
                </Card>
            }
        </div>
    )
}
