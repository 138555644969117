import React, {useEffect, useState} from 'react'
import {Button, Card, CircularProgress, TextField, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {t} from "i18next";
import {ILessonPlanResponse} from "../../utils/redux/model/lessonplan.model";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {toast} from "react-toastify";
import {chargeWalletAPI, chargeWalletWithDiscountAPI, checkCouponAPI} from "../../utils/redux/store/userSlice";
import {IChargeWalletResponse} from "../../utils/redux/model/chargeWallet.model";
import {getAllPaymentMethodAPI} from "../../utils/redux/store/paymentSlice";
import {IPaymentMethod} from "../../utils/redux/model/paymentMethod.model";

export const ChargeWallet = () => {
    const dispatch = useAppDispatch();
    const userBalance: ILessonPlanResponse = useAppSelector(state => state.auth.entity.userBalance);
    const entityCheckCouponAPI: {
        final_amount: number,
        discount_applied: number
    } = useAppSelector(state => state.user.entitycheckCouponAPI);
    const loadingCheckCouponAPI: boolean = useAppSelector(state => state.user.loadingcheckCouponAPI);
    const paymentMethodList: IPaymentMethod[] = useAppSelector(state => state.payment.entities);
    const [walletChargeAmount, setWalletChargeAmount] = useState(0)
    const [walletCoupon, setWalletCoupon] = useState("")
    const [loading, setLoading] = useState(false)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(1)
    const handlePayment = () => {
        if (walletChargeAmount === 0) {
            toast.warning(t("Wallet.EnterYourAmountForCharge"))
            return
        }
        setLoading(true)
        if (walletCoupon === "") {
            dispatch(chargeWalletAPI({
                amount: walletChargeAmount,
                coupon_code: walletCoupon,
                paymentGatewayId: selectedPaymentMethod
            }))
                .then((res) => {
                    const response: IChargeWalletResponse = (res.payload as any).data
                    setLoading(false)
                    if ((res.payload as any).status === 200) {
                        if (typeof response.url === "string") {
                            window.location.href = response.url
                        }
                    }
                })
        } else {
            dispatch(chargeWalletWithDiscountAPI({
                amount: walletChargeAmount,
                coupon_code: walletCoupon,
                paymentGatewayId: 1
            }))
                .then((res: any) => {
                    if (res.error) {
                        setLoading(false)
                        return
                    }
                    const response: IChargeWalletResponse = (res.payload as any).data
                    setLoading(false)
                    if ((res.payload as any).status === 200) {
                        if (typeof response.url === "string") {
                            window.location.href = response.url
                        }
                    }
                })
        }
    }
    const handleCheckCoupon = () => {
        dispatch(checkCouponAPI({transaction_amount: walletChargeAmount, coupon_code: walletCoupon}))
    }
    useEffect(() => {
        dispatch(getAllPaymentMethodAPI())
    }, []);
    return (
        <div className="row mx-0">
            {/* @ts-ignore*/}
            <Button
                LinkComponent={Link}
                to="/tools"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                {t('Back')}
            </Button>
            <Card variant="elevation" className="row mx-0 col-11 col-lg-7 mx-auto p-4 mt-5">
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3">{t('Wallet.ChargeWallet')}</Typography>
                </div>
                <div className="col-12 mt-3">
                    <Typography variant="subtitle1">{t('Wallet.currentBalance') + ": $" + userBalance}</Typography>
                </div>
                <div className="row col-12 mt-3">
                    <div className="col-12 align-content-center">
                        <Typography variant="subtitle1">
                            {t('Wallet.EnterYourAmountForCharge')}:
                        </Typography>
                    </div>
                    <div className="col-4">
                        <TextField
                            type="number"
                            autoComplete="false"
                            value={walletChargeAmount}
                            onKeyDown={(e: any) => {
                                if (e.key === 'ArrowUp') {
                                    setWalletChargeAmount(prevAmount => Number(prevAmount) + 4);
                                } else if (e.key === 'ArrowDown') {
                                    if (walletChargeAmount - 4 < 0) {
                                        return
                                    }
                                    setWalletChargeAmount(prevAmount => Number(prevAmount) - 4);
                                }
                            }}
                            onChange={(e: any) => {
                                if (walletChargeAmount - 4 < 0) {
                                    return
                                }
                                setWalletChargeAmount(e.target.value)
                            }}
                            fullWidth
                        />
                    </div>
                    <div className="row col-12 mt-3 px-0 mx-0">
                        <div className="col-12 align-content-center">
                            <Typography variant="subtitle1">
                                {t('Wallet.coupon')}:
                            </Typography>
                        </div>
                        <div className="row col-12 px-0 mx-0">
                            <div className="col-4">
                                <TextField
                                    type="text"
                                    autoComplete="false"
                                    value={walletCoupon}
                                    onChange={(e: any) => setWalletCoupon(e.target.value)}
                                    fullWidth
                                />
                            </div>
                            <div className="col-3 align-content-center">
                                <Button className="col-12" variant="contained" color="info"
                                        onClick={handleCheckCoupon}
                                >
                                    {loadingCheckCouponAPI ?
                                        <CircularProgress size={24}/>
                                        :
                                        t('Wallet.checkCoupon')
                                    }
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row col-8 mx-auto mt-3">
                    <div className="col-3">
                        <Button className="col-12" variant={walletChargeAmount === 5 ? "contained" : "outlined"}
                                color="primary"
                                onClick={() => setWalletChargeAmount(5)}
                        >
                            $5
                        </Button>
                    </div>
                    <div className="col-3">
                        <Button className="col-12" variant={walletChargeAmount === 10 ? "contained" : "outlined"}
                                color="primary"
                                onClick={() => setWalletChargeAmount(10)}
                        >
                            $10
                        </Button>
                    </div>
                    <div className="col-3">
                        <Button className="col-12" variant={walletChargeAmount === 20 ? "contained" : "outlined"}
                                color="primary"
                                onClick={() => setWalletChargeAmount(20)}
                        >
                            $20
                        </Button>
                    </div>
                    <div className="col-3">
                        <Button className="col-12" variant={walletChargeAmount === 100 ? "contained" : "outlined"}
                                color="primary"
                                onClick={() => setWalletChargeAmount(100)}
                        >
                            $100
                        </Button>
                    </div>
                </div>
                <div className="row col-12 mt-4">
                    <Typography variant="subtitle1">Payment Method</Typography>
                </div>
                <div className="row mx-0 col-12 mt-3">
                    {paymentMethodList.map((paymentMethod) => {
                        return (
                            <div className="col-3">
                                <Card key={paymentMethod.id}
                                      className={selectedPaymentMethod === paymentMethod.id ? "col-12 border border-2 bg-light" : "col-12 border"}
                                      variant="elevation"
                                      onClick={() => setSelectedPaymentMethod(paymentMethod.id!)}
                                >
                                    <div className="col-12 text-center">
                                        <img className="col-12" src={paymentMethod.icon!} alt={paymentMethod.name!}/>
                                    </div>
                                    <div className="col-12 px-0 text-center">{paymentMethod.name}</div>
                                </Card>
                            </div>
                        )
                    })}
                </div>
                <div className="row col-12 mt-3 px-0 px-lg-5">
                    <Button className="col-12 gap-2 " variant="contained" color="primary"
                            onClick={!loading ? handlePayment : () => {
                            }}
                    >
                        {loading ? <CircularProgress size={24}/> : t('pay')
                        }

                        <div className={entityCheckCouponAPI && "text-decoration-line-through"}>
                            ${walletChargeAmount}
                        </div>
                        {entityCheckCouponAPI &&
                            <div style={{color: "red"}}>
                                ${(walletChargeAmount - (entityCheckCouponAPI ? entityCheckCouponAPI.discount_applied : 0))}
                            </div>
                        }


                    </Button>
                </div>
            </Card>
        </div>
    )
}
