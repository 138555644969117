import React from 'react';
import { Typography} from "@mui/material";
import ChatContainer from "./chatContainer";

const RoleplayRoomChat = () => {
    const x = require('./chat.css')
    return (
        <div className="row col-12 p-3 py-4 mx-auto mt-5 rounded">
            <div className="col-12 mt-2 text-center">
                <Typography variant="h3">
                    ChatBox
                </Typography>
            </div>
            <div className="col-12 mt-2 ">
                <ChatContainer/>
            </div>
        </div>
    );
};

export default RoleplayRoomChat;

