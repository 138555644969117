import React, {useEffect, useState} from 'react'
import {
    Button,
    Card,
    Paper,
    Skeleton,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {t} from "i18next";
import {getAllInvitationListAPI, inviteToOrganizationAPI} from "../../utils/redux/store/organizationSlice";
import {reset} from "../../utils/redux/store/organizationSlice";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {useTranslation} from "react-i18next";
import {IOrganization} from "../../utils/redux/model/organization.model";
import {FiRepeat} from "react-icons/fi";
import {FaPlus, FaStar} from "react-icons/fa";
import {convertLinuxDateToDate} from "../../utils/convertLinuxDateToDate";
import {toast} from "react-toastify";

export const InviteTeachers = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const loading: boolean = useAppSelector(state => state.organization.loading);
    const entities: IOrganization[] = useAppSelector(state => state.organization.entities);
    const [reqData, setReqData] = useState({invitee_email: ""})
    useEffect(() => {
        dispatch(getAllInvitationListAPI())
        return () => {
            dispatch(reset())
        }
    }, []);
    const handleAddInvite = () => {
        dispatch(inviteToOrganizationAPI(reqData)).finally(() => {
            dispatch(getAllInvitationListAPI())
        })
        setReqData({...reqData, invitee_email: ""})
    }
    return (
        <div className="row mx-0">
            {/* @ts-ignore*/}
            <Button
                LinkComponent={Link}
                to="/tools"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                {t('Back')}
            </Button>
            <Card variant="elevation" className="row mx-0 col-11 col-lg-10 mx-auto p-4 mt-5">
                <div className="col-4 position-relative">
                    <Typography variant="subtitle1">{t('login.email')}:</Typography>
                    <TextField
                        className="col-12"
                        type="email"
                        placeholder={t('login.emailPlaceholder')}
                        value={reqData.invitee_email}
                        onChange={event => setReqData({...reqData, invitee_email: event.target.value})}
                    />
                </div>
                <div className="d-flex gap-2 col-1 align-items-center px-0">
                    <Button variant="text" color='primary' className="gap-0 mt-4 px-3"
                            startIcon={<FaPlus/>}
                            onClick={handleAddInvite}
                    >
                        {t('Add')}
                    </Button>
                </div>
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3">{t('organization.InviteTeachers')}</Typography>
                </div>
                {entities && entities.length > 0 ?
                    <TableContainer className="mt-3" component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>invitee email</TableCell>
                                    <TableCell>status</TableCell>
                                    <TableCell>Invited Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {entities.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell>{row.invitee_email}</TableCell>
                                        <TableCell
                                            className={row.status === "pending" ?
                                                "text-danger fw-bold"
                                                :
                                                row.status === "accepted" ?
                                                    "text-success fw-bold" :
                                                    "fw-bold"
                                            }>{row.status}</TableCell>
                                        <TableCell>{convertLinuxDateToDate(row.createdDate!)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant="h5" className="mt-3">{t("organization.noInvitedUserFound")}</Typography>
                }
            </Card>
            {loading &&
                <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 h-25">
                    <Skeleton className="row mx-0 col-12 mx-auto p-4 mt-5">
                    </Skeleton>
                </Card>
            }
        </div>
    )
}
