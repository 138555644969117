import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Button, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {useAppDispatch} from "../../utils/redux/store";
import {loginUser} from "../../utils/redux/store/authSlice";
// @ts-ignore
import MuiImageSlider from "mui-image-slider";
import loginslide1 from "../../assets/loginslide1.png";
import {FiCheck} from "react-icons/fi";
import {FaRegSquareFull} from "react-icons/fa6";
import {useTranslation} from "react-i18next";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";

export const Login = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation();
    const [reqData, setReqData] = useState({email: '', password: ''})
    const [showPassword, setShowPassword] = useState(false);
    const handleLogin = () => {
        dispatch(loginUser(reqData.email, reqData.password))
    }
    const handleToggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="row mx-0 h-100">
            <div className="col-12 col-lg-6 mt-5 px-5">
                <div className='row justify-content-center mt-5'>
                    <div className='col-12'>
                        <Typography className='text-center' variant='h2' component='h1'>
                            Login to Account
                        </Typography>
                    </div>
                    <div className='col-12 mt-3'>
                        <Typography className='text-center' variant='caption' component='h1'>
                            Welcome back! Please login to your account.
                        </Typography>
                    </div>
                </div>
                <div className='row justify-content-center mt-5'>
                    <div className='col-10'>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">{t('login.email')}:</Typography>
                            <TextField
                                className="col-12 mt-2"
                                variant="filled"
                                type="email"
                                placeholder={t('login.emailPlaceholder')}
                                onChange={(event) => setReqData({
                                    ...reqData,
                                    email: event.target.value
                                })}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <div className="row mx-0 col-12 px-0">
                                <div className="col-6 px-0">
                                    <Typography variant="subtitle1">{t('login.password')}:</Typography>
                                </div>
                                <div className="col-6 text-end px-0">
                                    <Typography className='col-12' variant='caption' component='h6'>
                                        <a href='/forget-password' style={{color: '#0D0D10'}}>Forget Password?</a>
                                    </Typography>
                                </div>
                            </div>
                            <TextField
                                className="col-12 mt-2"
                                variant="filled"
                                placeholder={t('login.passwordPlaceholder')}
                                type={showPassword ? "text" : "password"}
                                onChange={(event) => setReqData({
                                    ...reqData,
                                    password: event.target.value
                                })}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleToggleShowPassword}>
                                                {showPassword ? <MdVisibilityOff/> : <MdVisibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <FormControlLabel control={<Checkbox icon={<FaRegSquareFull size={18}/>}
                                                                 checkedIcon={<FiCheck size={18}/>}/>}
                                              label="Remember Password"/>
                        </div>
                        <div className="col-12 mt-4">
                            <div className="col-10 mx-auto mt-3 px-0 px-lg-4">
                                <Button className="col-12" variant="contained" color="primary"
                                        onClick={handleLogin}
                                >
                                    {t('login.SignIn')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 text-center mt-3'>
                    <Typography className='col-12 text-center' variant='h5' component='h6'>
                        Don’t have an account?
                        <a href='/register' style={{color: '#F87F35'}}> Create Account</a>
                    </Typography>
                </div>
            </div>
            <div className="col-12 col-lg-6 px-0 d-none d-lg-block">
                <MuiImageSlider
                    autoPlay
                    arrowsBgColor="#F4F4F4"
                    alwaysShowArrows
                    images={[
                        loginslide1,
                        loginslide1,
                        loginslide1,
                        loginslide1,
                    ]}
                />
            </div>

        </div>
    )

}
