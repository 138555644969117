import React, {useEffect, useState} from "react";
import {
    Button,
    Card, CircularProgress, IconButton, Skeleton, TextField,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {MdContentCopy, MdSettingsVoice} from "react-icons/md";
import {IoIosVolumeHigh} from "react-icons/io";
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "../../../utils/redux/store";
import {pdfToPPTXAPI, reset} from "../../../utils/redux/store/promptSlice";
import {useTranslation} from "react-i18next";
import {TfiExport} from "react-icons/tfi";
import {checkValidate} from "../../../utils/redux/store/authSlice";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import {Document, Packer, Paragraph, TextRun} from "docx";
import {saveAs} from "file-saver";
import {stripHtmlTags} from "../../../utils/stripHtmlTags";
import html2pdf from "html2pdf.js";

export const SlideGenerator = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const copyContentRef = React.useRef(null);
    const prompt = useAppSelector(state => state.prompt.entities);
    const loading: boolean = useAppSelector(state => state.prompt.loading);
    const [files, setFiles] = useState<File[]>([]);
    const [additionalContext, setAdditionalContext] = useState<string>("");
    const [numberOfSlides, setNumberOfSlides] = useState<number>(0);

    useEffect(() => {
        return () => {
            dispatch(reset())
        }
    }, []);


    const handleSlideGenerator = (e: { preventDefault: () => void; }) => {
        if (files.reduce((accumulator, file) => accumulator + file.size, 0) > 20971520) {
            toast.warning(t("toast.max20MBSize"))
            return
        }
        if (files?.length === 0) {
            toast.warning(t("toast.NoFileUploaded"))
            return
        }
        if (files?.length >= 6) {
            toast.warning(t("toast.Max5PDFAllowed"))
            return
        }
        if (numberOfSlides <= 0) {
            toast.warning(t("toast.numberOfSlidesMustNotZero"))
            return
        }
        if (additionalContext === "") {
            toast.warning(t("toast.additionalContextMustNotBeEmpty"))
            return
        }

        dispatch(pdfToPPTXAPI({
            files: files,
            additionalContext: additionalContext,
            numberOfSlides: numberOfSlides
        })).then((res: any) => {
            if (!res.payload.detail) {
                dispatch(checkValidate())
            }
        })
    }
    const handleCopy = () => {
        if (copyContentRef.current) {
            const content = (copyContentRef.current as any).innerHTML;

            // Create a temporary contenteditable div to hold the HTML
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.left = '-9999px';
            tempDiv.style.whiteSpace = 'pre';
            tempDiv.contentEditable = 'true';
            tempDiv.innerHTML = content;
            document.body.appendChild(tempDiv);

            // Select and copy the content
            const range = document.createRange();
            range.selectNodeContents(tempDiv);
            const sel: any = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            // Execute the copy command
            document.execCommand('copy');

            // Clean up
            document.body.removeChild(tempDiv);

            toast.success('Content copied to clipboard.');
        }
    }
    const handleExport = () => {
        if (!copyContentRef.current) {
            toast.error('No content to export');
            return;
        }
        const content = copyContentRef.current;
        const opt = {
            margin: 1,
            filename: 'slide.pdf',
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: 'in', format: 'A4', orientation: 'portrait'}
        };

        html2pdf().set(opt).from(content).save();


        const docs = new Document({
            sections: [{
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: stripHtmlTags((content as any).innerHTML),
                            break: 1
                        })
                    ]
                })]
            }]
        });
        Packer.toBlob(docs).then(blob => {
            saveAs(blob, 'slide.docx');
        });
    }
    const handleReadAloud = () => {
        toast.info("coming soon")
    }
    return (
        <div className="row mx-0">
            {/* @ts-ignore*/}
            <Button
                LinkComponent={Link}
                to="/tools"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                Back
            </Button>
            <Card variant="elevation" className="row mx-0 col-11 col-lg-7 mx-auto p-4 mt-5">
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3">{t('SlideGenerator.title')}</Typography>
                </div>
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3" className="fw-bold text-center poweredbyahura">{t('PoweredByAhuraAI')}</Typography>
                </div>
                <div className="col-12 text-center">
                    <Typography variant="caption">
                        {t('SlideGenerator.description')}
                    </Typography>
                </div>
                <div className="col-12 mt-3">
                    <Typography variant="subtitle1">{t('SlideGenerator.SelectFile')}:</Typography>
                    <Button
                        variant="contained"
                        component="label"
                        disabled={loading}
                    >
                        {files.length > 0 ? `${files.length} ${t('SlideGenerator.FilesSelected')} ` : t('SlideGenerator.SelectFiles')}
                        <input
                            type="file"
                            accept=".pdf"
                            size={20971520}
                            hidden
                            multiple
                            onChange={(event: any) => setFiles(Array.from(event.target.files))}
                        />
                    </Button>
                </div>
                <div className="col-12 mt-3">
                    <Typography variant="subtitle1">{t('SlideGenerator.AdditionalContext')}:</Typography>
                    <TextField
                        className="col-12"
                        placeholder={t('SlideGenerator.AdditionalContextDesc')}
                        multiline
                        rows={6}
                        value={additionalContext}
                        onChange={(e) => setAdditionalContext(e.target.value)}
                    />
                    <div className='position-absolute right-4 bottom-2'>
                        <IconButton className="col-12">
                            <MdSettingsVoice/>
                        </IconButton>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <Typography variant="subtitle1">{t('SlideGenerator.NumberOfSlides')}:</Typography>
                    <TextField
                        className="col-12"
                        type="number"
                        value={numberOfSlides}
                        onChange={(event) => setNumberOfSlides(Number(event.target.value))}
                    />
                    <div className='position-absolute right-4 bottom-2'>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleSlideGenerator}
                    >
                        {loading ? <CircularProgress size={24}/> : t('SlideGenerator.button')}
                    </Button>
                </div>


            </Card>
            {prompt.result && (
                <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5">
                    <div ref={copyContentRef}
                         dangerouslySetInnerHTML={{__html: prompt.result}}
                    />
                    <div className="d-flex gap-3 col-12 text-start">
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<MdContentCopy/>}
                                    onClick={handleCopy}
                            >
                                {t('Copy')}
                            </Button>
                        </div>
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<TfiExport/>}
                                    onClick={handleExport}
                            >
                                {t('Export')}
                            </Button>
                        </div>
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<IoIosVolumeHigh/>}
                            onClick={handleReadAloud}>
                                {t('ReadAloud')}
                            </Button>
                        </div>

                    </div>
                </Card>
            )}
            {loading &&
                <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5 h-25">
                    <Skeleton className="row mx-0 col-7 mx-auto p-4 mt-5">
                    </Skeleton>
                </Card>
            }

        </div>
    )
}
